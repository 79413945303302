import React from 'react';
import settings from '../../../settings';

import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import SponsorsList from '../../components/sponsors/sponsorslist';

const sponsors = [
  {
    id: 1,
    image: `${settings.IMAGES_BASE_URL}/v2/images/mini/sponsors/desktop/mini-continental-tire-sponsor.jpg`,
    title: 'Continental Tires',
    imageAlt: 'Mini Continental Tire Sponsor',
    text: "A trusted supplier since 1999 (our inaugural year, each of the 2,500 sets of tires we've used since then has enabled us to push our BMWs to the max with confidence.",
    url: "https://www.continentaltire.com/"
  },
  {
    id: 2,
    image: `${settings.IMAGES_BASE_URL}/v2/images/bmw-clubCar-box.jpg`,
    title: 'Club Car',
    imageAlt: 'Club Car',
    text:
      'The best way to get around our facility, club car makes gas or electric-powered carts that fit many lifestyles.',
      url: 'https://www.clubcar.com/us/en/home.html'
  },
  {
    id: 3,
    image: `${settings.IMAGES_BASE_URL}/v2/images/mini/sponsors/desktop/mini-thermal-club-sponsor-box.jpg`,
    title: 'The Thermal Club',
    imageAlt: 'The Thermal Club',
    text:
      'Home to the Performance Center West, The Thermal Club boasts world-class facilities and luxurious accommodations, all surrounded by the thrill of motorsport.',
      url: 'http://thethermalclub.com/'
  },
  {
    id: 4,
    image: `${settings.IMAGES_BASE_URL}/v2/images/mini/sponsors/desktop/mini-nhtsa-sponsor-box.jpg`,
    imageAlt: 'Mini NHTSA Sponsor Box',
    title: 'National Highway Traffic Safety Administration',
    text:
      'A key component in keeping both people and vehicles safe, the NSC has been a sponsor of the BMW Performance Center and MINI Driving Experience since its inception.',
    url: "https://www.nhtsa.gov"
  },
  {
    id: 5,
    image: `${settings.IMAGES_BASE_URL}/v2/images/mini/sponsors/desktop/mini-partner-page-tile.jpg`,
    imageAlt: 'Mini Reward',
    title: 'Fuel The Fun',
    text:
      'Accelerate your rewards with the MINI Card - exclusively for MINI drivers. With up to 4X points per $1 spent on eligible MINI purchases, earning has never been so easy.',
    url: "http://www.myminicreditcard.com/37991"
  }
];

const PartnersPage = () => {
  return (
    <Layout>
      <SEO title="THE MINI DRIVING EXPERIENCE | Partners" />

      <div className="sponsors-page">
        <section className="hero__header">
          <div className="container">
            <div className="hero__header__content">
              <h1>Partners</h1>
            </div>
          </div>
        </section>
        <section className="sponsors">
          <div className="container">
            <SponsorsList sponsors={sponsors} />
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default PartnersPage;
